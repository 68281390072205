import React from 'react';
import MdRender from '../Common/MdRender'
import {
  BaseComponent,
  HRule, Row, Portion
} from 'reactised-fictoan';

import HelpFooterBBPS from '../Common/HelpFooterBBPS'

class BbpsBiller extends React.PureComponent {
  
  render () {
    console.log("rendering");
    return (
      <BaseComponent Element="article" id="page-fastag">
         <Row
        sidePadding="large"
        id="getting-started"
        className="content-padding-large"
      >
        <Portion>
        <MdRender contentKey="bbpsBiller"></MdRender>
          </Portion></Row>
        
        <HRule thin bgColor="slate-40" sideMargin="large" />
        <HelpFooterBBPS></HelpFooterBBPS>
      </BaseComponent>
    );
  }
}

export default BbpsBiller;
