
import React from 'react';
import {Portion} from 'reactised-fictoan';
import * as showdown  from 'showdown';
import Content from "../../content/Content"

let mdConverter = new showdown.Converter()
mdConverter.setOption("openLinksInNewWindow", true);

class MdRender extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            content: "Loading"
        };
    }
    componentDidMount() {
        if (this.props.contentKey){
            let content = Content[this.props.contentKey];

            console.log("Content", content)
            this.setState({
                content: content
            });
        }
        
    }

    render() {
        let htmlContent = mdConverter.makeHtml(this.state.content);
        return (
            <Portion className="rendered-markdown" dangerouslySetInnerHTML={{ __html: htmlContent }}>
            </Portion>);
    }
}

export default MdRender;
