import React from 'react';

import {
  Row,
  Portion,
  Text
} from 'reactised-fictoan';


class HelpFooter extends React.PureComponent {

  render () {
    return (
        <Row sidePadding="large" marginBottom="small">
            <Portion>
                <Text weight="600" marginBottom="none">Need further help?</Text>
                <Text>
                    If you have any queries, just send us an email to
              {' '}
                    <a
                        className="weight-400"
                        href="mailto:axis-bbps@setu.co?subject=Help with BBPS"
                    >
                        axis-bbps@setu.co
              </a>
                    , and we will help you to quickly get back on track.
            </Text>
            </Portion>
        </Row>

    );
  }
}

export default HelpFooter;
