import React from 'react';
import { Link } from 'react-router-dom'

import { Row, Portion } from 'reactised-fictoan';

import logoAxis from "../../assets/images/logo-axis.svg"

class Header extends React.PureComponent {
    render() {
        return (
            <Row className="docs-header bg-white shadow-mild vertically-centre-items show-only-on-mobile show-only-on-tab-pt">
                <Portion className="whole horizontal vertically-centre-items no-margin-bottom">
                    <div className="menu-toggle" onClick={this.props.onMenuBtnClick}>
                        <h6 className="">&mdash;</h6>
                        <h6 className="">&ndash;</h6>
                    </div>

                    <Link to="/" className="main-header-logo">
                        <img src={logoAxis} className="App-logo margin-right-fixed" alt="logo" />
                    </Link>
                </Portion>
            </Row >
        )
    }
}

export default Header
