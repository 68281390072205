import React from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

import {
    BaseComponent,
    Row,
    Portion,
    Heading,
    Text,
    Card,
    Button,
} from "reactised-fictoan";

class Home extends React.PureComponent {
    componentDidMount() {
        document.title = "Axis Bank APIs";
    }

    render() {
        return (
            <BaseComponent Element="article" id="page-home">
                <Row sidePadding="large" marginTop="small">
                    <Portion>
                        <Heading type="h3">Axis Bank APIs</Heading>
                        <Text marginTop="small">
                            State of the art APIs from Axis bank to enable
                            financial usecases in your web & mobile
                            applications.
                        </Text>
                    </Portion>
                </Row>

                <Row sidePadding="large" marginTop="small" gutters="medium">
                    {/* <Portion desktopSpan="half">
                        <Card padding="large" shape="rounded" shadow="hard" bgColor="white" theme={{ card: { bg: "white" }}}>
                            <Heading type="h6">Corporate</Heading>
                            <Text>Activate and manage FASTags with your Axis current account.</Text>

                            <NavLink to="/product/corporate">
                                <Button shape="rounded" bgColor="crimson-90" textColor="white" value="Explore &rarr;" shadow="hard" />
                            </NavLink>
                        </Card>
                    </Portion> */}

                    {/* <Portion desktopSpan="half">
                        <Card padding="large" shape="rounded" shadow="hard" bgColor="white" theme={{ card: { bg: "white" }}}>
                            <Heading type="h6">FASTag</Heading>
                            <Text>Issue & Maintain FASTags for your customers</Text>

                            <NavLink to="/product/user-flow">
                                <Button shape="rounded" bgColor="crimson-90" textColor="white" value="Explore &rarr;" shadow="hard" />
                            </NavLink>
                        </Card>
                    </Portion> */}
                    <Portion desktopSpan="half">
                        <Card
                            padding="large"
                            shape="rounded"
                            shadow="hard"
                            bgColor="white"
                            theme={{ card: { bg: "white" } }}
                        >
                            <Heading type="h6">Bill Collection</Heading>
                            <Text>
                                Do bill collections on BBPS for your business
                            </Text>

                            <NavLink to="/bbps/biller/docs">
                                <Button
                                    shape="rounded"
                                    bgColor="crimson-90"
                                    textColor="white"
                                    value="Explore &rarr;"
                                    shadow="hard"
                                />
                            </NavLink>
                        </Card>
                    </Portion>
                    <Portion desktopSpan="half">
                        <Card
                            padding="large"
                            shape="rounded"
                            shadow="hard"
                            bgColor="white"
                            theme={{ card: { bg: "white" } }}
                        >
                            <Heading type="h6">Bill Payments</Heading>
                            <Text>
                                Do bill payments on BBPS as an agent instituion
                            </Text>

                            <NavLink to="/cou/api">
                                <Button
                                    shape="rounded"
                                    bgColor="crimson-90"
                                    textColor="white"
                                    value="Explore &rarr;"
                                    shadow="hard"
                                />
                            </NavLink>
                        </Card>
                    </Portion>
                </Row>
            </BaseComponent>
        );
    }
}

export default Home;
