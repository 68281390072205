import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './index.scss'

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-134950679-2');
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<App />, document.getElementById('root'));
