/* eslint-disable no-useless-escape */
export const cSharp = `
using System;
using System.IdentityModel.Tokens.Jwt;
using System.Security.Claims;
using System.Text;
using Microsoft.IdentityModel.Tokens;


public class JwtAuthManager
{
    static string SecretKey = "ReallyLongSecret"; // this should come from your configuration file
    static string audience = "YourAudClaim";// this should come from your configuration file

    public string GenerateJWTToken()
    {
        byte[] symmetricKey = Encoding.ASCII.GetBytes(JwtAuthManager.SecretKey);
        var token_Handler = new JwtSecurityTokenHandler();

        var now = DateTime.UtcNow;
        var securitytokenDescriptor = new SecurityTokenDescriptor
        {
            Audience = JwtAuthManager.audience,
            IssuedAt = now,
            SigningCredentials = new SigningCredentials(new SymmetricSecurityKey(symmetricKey), SecurityAlgorithms.HmacSha256)
        };

        var stoken = token_Handler.CreateToken(securitytokenDescriptor);
        var token = token_Handler.WriteToken(stoken);

        return token;
    }

    public bool ValidateToken(string token)
    {
        var simplePrinciple = JwtAuthManager.GetPrincipal(token);
        Console.WriteLine(simplePrinciple);
        if (simplePrinciple == null)
            return false;
        // You can implement more validation to check whether username exists in your DB or not or something else. 

        return true;
    }

    public static ClaimsPrincipal GetPrincipal(string token)
    {

        try
        {
            var jwtTokenHandler = new JwtSecurityTokenHandler();
            var jwtToken = jwtTokenHandler.ReadToken(token) as JwtSecurityToken;

            if (jwtToken == null)
                return null;

            byte[] symmetricKey = Encoding.ASCII.GetBytes(JwtAuthManager.SecretKey);

            var validationParameters = new TokenValidationParameters()
            {
                ValidateAudience = true,
                RequireExpirationTime = false,
                ValidateIssuer = false,
                ValidAudience = JwtAuthManager.audience,
                IssuerSigningKey = new SymmetricSecurityKey(symmetricKey)
            };

            SecurityToken securityToken;
            var principal = jwtTokenHandler.ValidateToken(token, validationParameters, out securityToken);
            Console.WriteLine("Principle");
            Console.WriteLine(principal);
            return principal;
        }
        catch (Exception e)
        {
            Console.WriteLine(e);
            return null;
        }
    }
}
`

export const java = `
package com.setu.helpers;

import com.auth0.jwt.JWT;
import com.auth0.jwt.JWTVerifier;
import com.auth0.jwt.algorithms.Algorithm;
import com.auth0.jwt.exceptions.JWTVerificationException;
import com.auth0.jwt.interfaces.DecodedJWT;

import java.util.Date;
import java.util.UUID;

public class SetuJwtHelper {
    private String schemedId;
    private String secret;

    public SetuJwtHelper(String secret, String schemedId) {
        this.schemedId = schemedId;
        this.secret = secret;
    }

    public void setSchemedId(String schemedId) {
        this.schemedId = schemedId;
    }

    public void setSecret(String secret) {
        this.secret = secret;
    }

    public String yieldBearerToken() {
        Algorithm algorithm = Algorithm.HMAC256(this.secret);
        String token = JWT.create()
                .withAudience(this.schemedId)
                .withIssuedAt(new Date())
                .withClaim("jti",  UUID.randomUUID().toString())
                .sign(algorithm);
        return "Bearer " + token;
    }

    public void verifyBearerToken (String bearerToken) throws JWTVerificationException {
        bearerToken = bearerToken.replace("Bearer ", "");
        Algorithm algorithm = Algorithm.HMAC256(this.secret);
        JWTVerifier verifier = JWT.require(algorithm)
                .acceptLeeway(10)
                .withAudience(this.schemedId)
                .build(); //Reusable verifier instance
        DecodedJWT jwt = verifier.verify(bearerToken);
    }
}
`

export const php = `
<?php
require __DIR__.'/vendor/autoload.php';
use \Firebase\JWT\JWT;

$key = "7c2e036c-908f-48ba-abe3-cd8745a6fa99";
$aud = "8f47e41c-4684-4af1-b1b8-246c83112033";


// create token

$token = array(
    "aud" => $aud,
    "iat" => 1572493356,
    "jti" => "c53447e3-993a-44ae-9c78-31f45c3c082e"
);

$jwt = JWT::encode($token, $key);
print_r($jwt);

echo '<br/>';


// Decode Logic


/**
 * You can add a leeway to account for when there is a clock skew times between
 * the signing and verifying servers. It is recommended that this leeway should
 * not be bigger than a few minutes.
 *
 * Source: http://self-issued.info/docs/draft-ietf-oauth-json-web-token.html#nbfDef
 */


JWT::$leeway = 60; // $leeway in seconds
$decoded = JWT::decode($jwt, $key, array('HS256'));

$audClaimInToken = $decoded -> aud;

print_r($audClaimInToken);
echo '<br/>';


if($audClaimInToken == $aud){
  echo "Success";
}
else{
  echo "Fail";
}

?>
`

export const python = `
import jwt
import datetime
import uuid

scheme_id = "8f47e41c-4684-4af1-b1b8-246c83112033"
secret    = "7c2e036c-908f-48ba-abe3-cd8745a6fa99"
payload   = {
    "aud" : scheme_id,
    "iat" : datetime.datetime.utcnow(),
    "jti" : str(uuid.uuid1())
}

# generate a token like this

token = jwt.encode(payload, secret, algorithm="HS256")

# And to decode the token and verify the aud claim—


try:
    # verified claim
    jwt.decode(token, secret, audience=api_key)
    print("Verified token")
except jwt.PyJWTError:
    # unverified claim
    print("Token error")
`