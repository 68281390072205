import React from 'react'
// import {HashLink as Link} from "react-router-hash-link";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import HelpFooter from "../../Common/HelpFooter";
import {
    BaseComponent,
    Row,
    Portion,
    Text,
    HRule,
    Heading,
} from 'reactised-fictoan'

import Highlight, {defaultProps} from "prism-react-renderer"

import {
    cSharp,
    java,
    php,
    python
} from "./SampleCodes"


///////////////////////////////////////////////////////////////////////////////
const snippetJWTSample1 = `
// Auth header format
Authorization: Bearer <JWT_TOKEN>

// Sample JWT encoded
Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiI4ZjQ3ZTQxYy00Njg0LTRhZjEtYjFiOC0yNDZjODMxMTIwMzMiLCJpYXQiOjE1NzI0OTMzNTYsImp0aSI6ImM1MzQ0N2UzLTk5M2EtNDRhZS05Yzc4LTMxZjQ1YzNjMDgyZSJ9.xfYii9lfWMHtMBgjK5U6NFN_6_Q9jw8UMQu8Jnvftbs
`

///////////////////////////////////////////////////////////////////////////////
const snippetJWTSample2 = `
// JWT format
<JWT_HEADER>.<JWT_PAYLOAD>.<SIGNATURE>

// Sample JWT decoded
{
    "alg" : "HS256",
    "typ" : "JWT"
},
{
    "aud" : "8f47e41c-4684-4af1-b1b8-246c83112033", // This is the schemeID
    "iat" : 1572493356,
    "jti" : "c53447e3-993a-44ae-9c78-31f45c3c082e"
}
`;

///////////////////////////////////////////////////////////////////////////////
const snippetJWTSample3 = `
header    : eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9
payload   : eyJhdWQiOiI4ZjQ3ZTQxYy00Njg0LTRhZjEtYjFiOC0yNDZjODMxMTIwMzMiLCJpYXQiOjE1NzI0OTMzNTYsImp0aSI6ImM1MzQ0N2UzLTk5M2EtNDRhZS05Yzc4LTMxZjQ1YzNjMDgyZSJ9
signature : xfYii9lfWMHtMBgjK5U6NFN_6_Q9jw8UMQu8Jnvftbs
`;

///////////////////////////////////////////////////////////////////////////////
const snippetJWTSample4 = `
// Sample JWT header
{
  "alg" : "HS256",
  "typ" : "JWT"
}

// is encoded to
eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9
`;

///////////////////////////////////////////////////////////////////////////////
const snippetJWTSample5 = `
// Sample JWT payload
{
    "aud" : "8f47e41c-4684-4af1-b1b8-246c83112033", // This is the schemeID
    "iat" : 1572493356,
    "jti" : "c53447e3-993a-44ae-9c78-31f45c3c082e"
}

// is encoded to
eyJhdWQiOiI4ZjQ3ZTQxYy00Njg0LTRhZjEtYjFiOC0yNDZjODMxMTIwMzMiLCJpYXQiOjE1NzI0OTMzNTYsImp0aSI6ImM1MzQ0N2UzLTk5M2EtNDRhZS05Yzc4LTMxZjQ1YzNjMDgyZSJ9
`;

///////////////////////////////////////////////////////////////////////////////
const snippetJWTSample6 = `
HMACSHA256(
    base64UrlEncode(header) + "." +
    base64UrlEncode(payload),
    secret)
`;


///////////////////////////////////////////////////////////////////////////////
const snippetJWTSample7 = `
Input string   : eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiI4ZjQ3ZTQxYy00Njg0LTRhZjEtYjFiOC0yNDZjODMxMTIwMzMiLCJpYXQiOjE1NzI0OTMzNTYsImp0aSI6ImM1MzQ0N2UzLTk5M2EtNDRhZS05Yzc4LTMxZjQ1YzNjMDgyZSJ9
Secret         : 7c2e036c-908f-48ba-abe3-cd8745a6fa99
HA256 Signaure : xfYii9lfWMHtMBgjK5U6NFN_6_Q9jw8UMQu8Jnvftbs
`;


///////////////////////////////////////////////////////////////////////////////
// eslint-disable-next-line
const snippetJWTSample8 = `echo -n "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiI4ZjQ3ZTQxYy00Njg0LTRhZjEtYjFiOC0yNDZjODMxMTIwMzMiLCJpYXQiOjE1NzI0OTMzNTYsImp0aSI6ImM1MzQ0N2UzLTk5M2EtNDRhZS05Yzc4LTMxZjQ1YzNjMDgyZSJ9.xfYii9lfWMHtMBgjK5U6NFN_6_Q9jw8UMQu8Jnvftbs" | openssl dgst -sha256 -hmac BBzKUWAZeAS2tBsk0FtJT4ep -binary|openssl base64 -e -A | sed s/\+/-/ | sed -E s/=+$//
`;





class JWT extends React.PureComponent {
    componentDidMount() {
        document.title = "JWT — Axis";

        window.scroll(0, 0);
    }

    render() {
        return (
            <BaseComponent Element="article" id="page-jwt">
                {/* ======================================================================================================================
                    01 INTRO
                    ======================================================================================================================  */}

                <Row id="jwt" sidePadding="large" marginTop="small">
                    <Portion>
                        <Heading type="h2" marginBottom="fixed">JWT explained</Heading>
                        <Heading type="h4" weight="400" marginBottom="tiny">The JSON Web Token authentication mechanism explained for Axis APIs.</Heading>
                        <Text>The JSON Web Token is an open standard for representing claims securely between two parties. The <a href="https://jwt.io/" target="_blank" rel="noreferrer noopener">JWT website {`\u2197`}</a> covers the basics of how it works and the concepts involved. This guide tries to provide a simple description of how JWT is implemented at Setu.</Text>
                    </Portion>
                </Row>


                <HRule thin bgColor="slate-40" sideMargin="large" />

                {/* ======================================================================================================================
                02 BASICS
                ======================================================================================================================  */}


                <Row sidePadding="large" marginBottom="none">
                    <Portion paddingBottom="none">
                        <Heading type="h4" marginBottom="fixed">The basics</Heading>
                        <Text marginBottom="none">The JWT is sent as part of the authorisation header of every web request. Typically it looks like this&mdash;</Text>
                    </Portion>

                    <Portion marginTop="fixed" paddingTop="none">
                        <Highlight {...defaultProps} code={snippetJWTSample1} language="java" theme={undefined}>
                            {({className, style, tokens, getLineProps, getTokenProps}) => (
                                <pre className={className} style={style}>
                                    {tokens.map((line, i) => (
                                        <div {...getLineProps({line, key: i})}>
                                            {line.map((token, key) => (
                                                <span {...getTokenProps({token, key})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </Portion>
                </Row>

                <Row sidePadding="large" className="no-margin-bottom">
                    <Portion paddingBottom="none">
                        <Text marginBottom="none">The JWT in itself has three parts&mdash; A <code>header</code>, <code>payload</code>, and a <code>signature</code>. It’s usually in the following format&mdash;</Text>
                    </Portion>


                    <Portion marginTop="fixed" paddingTop="none">
                        <Highlight {...defaultProps} code={snippetJWTSample2} language="java" theme={undefined}>
                            {({className, style, tokens, getLineProps, getTokenProps}) => (
                                <pre className={className} style={style}>
                                    {tokens.map((line, i) => (
                                        <div {...getLineProps({line, key: i})}>
                                            {line.map((token, key) => (
                                                <span {...getTokenProps({token, key})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </Portion>
                </Row>


                <Row sidePadding="large" className="no-margin-bottom">
                    <Portion paddingBottom="none">
                        <Text marginBottom="none">This means that&mdash;</Text>
                    </Portion>

                    <Portion marginTop="fixed" paddingTop="none">
                        <Highlight {...defaultProps} code={snippetJWTSample3} language="java" theme={undefined}>
                            {({className, style, tokens, getLineProps, getTokenProps}) => (
                                <pre className={className} style={style}>
                                    {tokens.map((line, i) => (
                                        <div {...getLineProps({line, key: i})}>
                                            {line.map((token, key) => (
                                                <span {...getTokenProps({token, key})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </Portion>
                </Row>

                <HRule thin bgColor="slate-40" sideMargin="large" />


                {/* ======================================================================================================================
                03 HEADER
                ======================================================================================================================  */}
                <Row sidePadding="large" marginBottom="none">
                    <Portion paddingBottom="none">
                        <Heading type="h4" marginBottom="fixed">Header</Heading>
                        <Text marginBottom="none">This is a <code>base64</code> encoded value. It contains details about the algorithm used to calculate the <code>signature</code>. This could have values such as HMAC SH25Text or RSA. For example&mdash;</Text>
                    </Portion>

                    <Portion marginTop="fixed" paddingTop="none">
                        <Highlight {...defaultProps} code={snippetJWTSample4} language="java" theme={undefined}>
                            {({className, style, tokens, getLineProps, getTokenProps}) => (
                                <pre className={className} style={style}>
                                    {tokens.map((line, i) => (
                                        <div {...getLineProps({line, key: i})}>
                                            {line.map((token, key) => (
                                                <span {...getTokenProps({token, key})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </Portion>
                </Row>

                <HRule thin bgColor="slate-40" sideMargin="large" />


                {/* ======================================================================================================================
                04 PAYLOAD
                ======================================================================================================================  */}
                <Row sidePadding="large" className="no-margin-bottom">
                    <Portion paddingBottom="none">
                        <Heading type="h4" marginBottom="fixed">Payload</Heading>
                        <Text marginBottom="none">This is also a <code>base64</code> encoded value. This is the main part of the JWT, and contains details about the entity, and the request made (also called “claims”). Foext example&mdash;</Text>
                    </Portion>

                    <Portion marginTop="fixed" paddingTop="none">
                        <Highlight {...defaultProps} code={snippetJWTSample5} language="java" theme={undefined}>
                            {({className, style, tokens, getLineProps, getTokenProps}) => (
                                <pre className={className} style={style}>
                                    {tokens.map((line, i) => (
                                        <div {...getLineProps({line, key: i})}>
                                            {line.map((token, key) => (
                                                <span {...getTokenProps({token, key})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </Portion>

                    <Portion>
                        <Text><code>aud</code> is the <code>scheme_id</code> in JWT. This acts as the shared secret between you and Setu. The entity sending the API response shares this with the person making the API requests. This should be shared with Setu if Setu is making calls to your API. Setu would share this value, if you make calls to Setu APIs.</Text>

                        <Text><code>iat</code> is the time at which the request was issued, in milliseconds since the UNIX epoch. Requests older than 120000 milliseconds (2 minutes) are considered stale, and hence must be rejected.</Text>

                        <Text marginBottom="none"><code>jti</code> is the unique ID per request.</Text>
                    </Portion>
                </Row>

                <HRule thin bgColor="slate-40" sideMargin="large" />

                {/* ======================================================================================================================
                04 PAYLOAD
                ======================================================================================================================  */}
                <Row sidePadding="large">
                    <Portion paddingBottom="none">
                        <Heading type="h4" marginBottom="fixed">Signature</Heading>
                        <Text marginBottom="none">Using the algorithm specified in the header, the signature is constructed in the following way&mdash;</Text>
                    </Portion>

                    <Portion marginTop="fixed" paddingTop="none">
                        <Highlight {...defaultProps} code={snippetJWTSample6} language="java" theme={undefined}>
                            {({className, style, tokens, getLineProps, getTokenProps}) => (
                                <pre className={className} style={style}>
                                    {tokens.map((line, i) => (
                                        <div {...getLineProps({line, key: i})}>
                                            {line.map((token, key) => (
                                                <span {...getTokenProps({token, key})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </Portion>

                    <Portion paddingBottom="none">
                        <Text marginBottom="none">The secret is a key shared between Setu and you. This signature can be used to verify if the claims being made in the payload are valid and you can accept or reject the incoming request based on the validity of the signature. For example&mdash;</Text>
                    </Portion>

                    <Portion marginTop="fixed" paddingTop="none">
                        <Highlight {...defaultProps} code={snippetJWTSample7} language="java" theme={undefined}>
                            {({className, style, tokens, getLineProps, getTokenProps}) => (
                                <pre className={className} style={style}>
                                    {tokens.map((line, i) => (
                                        <div {...getLineProps({line, key: i})}>
                                            {line.map((token, key) => (
                                                <span {...getTokenProps({token, key})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </Portion>

                    <Portion paddingBottom="none">
                        <Text marginBottom="none">You can try this on your command line by running&mdash;</Text>
                    </Portion>

                    <Portion marginTop="fixed" paddingTop="none">
                        <Highlight {...defaultProps} code={snippetJWTSample8} language="java" theme={undefined}>
                            {({className, style, tokens, getLineProps, getTokenProps}) => (
                                <pre className={className} style={style}>
                                    {tokens.map((line, i) => (
                                        <div {...getLineProps({line, key: i})}>
                                            {line.map((token, key) => (
                                                <span {...getTokenProps({token, key})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </Portion>

                    <Portion>
                        <Text marginBottom="none">Read more about how we arrived at this command <a href="https://gist.github.com/cheapRoc/5cbe11e9a8831c9e73f55121d4eddacf" target="_blank" rel="noopener noreferrer">here {`\u2197`}</a>.</Text>
                    </Portion>
                </Row>

                <HRule thin bgColor="slate-40" sideMargin="large" />


                {/* ======================================================================================================================
                04 PAYLOAD
                ======================================================================================================================  */}
                <Row sidePadding="large" marginBottom="small">
                    <Portion paddingBottom="none">
                        <Heading type="h4" marginBottom="fixed">Sample code</Heading>
                        <Text>Practically, you never need to worry about the encoding and decoding a JWT. A lot of third party libraries exist that can do this for you easily in most programming languages. Below here are some samples in popular languages&mdash;</Text>
                    </Portion>

                    <Portion paddingBottom="none" className="horizontal">
                        <Tabs className="full-width">
                            <TabList>
                                <Tab>Python</Tab>
                                <Tab>PHP</Tab>
                                <Tab>C#</Tab>
                                <Tab>Java</Tab>
                            </TabList>

                            <TabPanel>
                                <Highlight {...defaultProps} code={python} language="python" theme={undefined}>
                                    {({className, style, tokens, getLineProps, getTokenProps}) => (
                                        <pre className={className} style={style}>
                                            {tokens.map((line, i) => (
                                                <div {...getLineProps({line, key: i})}>
                                                    {line.map((token, key) => (
                                                        <span {...getTokenProps({token, key})} />
                                                    ))}
                                                </div>
                                            ))}
                                        </pre>
                                    )}
                                </Highlight>
                            </TabPanel>

                            <TabPanel>
                                <Highlight {...defaultProps} code={php} language="php" theme={undefined}>
                                    {({className, style, tokens, getLineProps, getTokenProps}) => (
                                        <pre className={className} style={style}>
                                            {tokens.map((line, i) => (
                                                <div {...getLineProps({line, key: i})}>
                                                    {line.map((token, key) => (
                                                        <span {...getTokenProps({token, key})} />
                                                    ))}
                                                </div>
                                            ))}
                                        </pre>
                                    )}
                                </Highlight>
                            </TabPanel>

                            <TabPanel>
                                <Highlight {...defaultProps} code={cSharp} language="java" theme={undefined}>
                                    {({className, style, tokens, getLineProps, getTokenProps}) => (
                                        <pre className={className} style={style}>
                                            {tokens.map((line, i) => (
                                                <div {...getLineProps({line, key: i})}>
                                                    {line.map((token, key) => (
                                                        <span {...getTokenProps({token, key})} />
                                                    ))}
                                                </div>
                                            ))}
                                        </pre>
                                    )}
                                </Highlight>
                            </TabPanel>

                            <TabPanel>
                                <Highlight {...defaultProps} code={java} language="java" theme={undefined}>
                                    {({className, style, tokens, getLineProps, getTokenProps}) => (
                                        <pre className={className} style={style}>
                                            {tokens.map((line, i) => (
                                                <div {...getLineProps({line, key: i})}>
                                                    {line.map((token, key) => (
                                                        <span {...getTokenProps({token, key})} />
                                                    ))}
                                                </div>
                                            ))}
                                        </pre>
                                    )}
                                </Highlight>
                            </TabPanel>
                        </Tabs>
                    </Portion>

                    <Portion paddingBottom="none">
                        <Text marginBottom="none">You can accept this request as legitimate and proceed to take further action. You can reject a request if&mdash;</Text>

                        <ol>
                            <li>The time since generation of the token is more than 2 minutes.</li>
                            <li>When the <code>aud</code> claim is not verified.</li>
                            <li>When the signature is invalid.</li>
                        </ol>
                    </Portion>
                </Row>


                <HRule thin bgColor="slate-40" sideMargin="large" />

                <HelpFooter></HelpFooter>

                                                        

            </BaseComponent>
        );
    }
}

export default JWT
