import React, { useState } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { ContentWrapper } from "reactised-fictoan";

import Sidebar from "../Sidebar/Sidebar";
import Header from "../../components/Header/Header";

import Home from "../../pages/Home/Home";

import JWT from "../../pages/Guides/JWT/JWT";
import Error404 from "pages/404/404";

import CouAPIReference from "../../pages/APIReference/CouAPIReference";
import InstaCollectAPIReference from "../../pages/APIReference/InstaCollectAPIReference";
import BbpsBiller from "pages/FASTag/BbpsBiller";

// Commented FASTAG pages

// import Encryption from "../../pages/Guides/Encryption/Encryption";
// import BcApi from "../../pages/Guides/BcApi/BcApi";
// import RechargeApi from "../../pages/Guides/BcApi/RechargeApi";
// import IntegrationGuide from "../../pages/Guides/BcApi/IntegrationGuide";
// import JavaSdk from "../../pages/Guides/BcApi/JavaSdk";
// import APIReference from "../../pages/APIReference/APIReference";
// import ResponseCodes from "../../pages/FASTag/ResponseCodes/ResponseCodes";
// import FASTag from "../../pages/FASTag/FASTag";
// import BcFullPage from "../../pages/FASTag/BcFullPage";
// import Notifications from "../../pages/FASTag/Notifications";
// import NotificationsAxis from "../../pages/FASTag/NotificationsAxis";
// import CorpFullPage from "../../pages/FASTag/CorpFullPage";
// import IntegrationFullPage from "pages/FASTag/IntegrationFullPage";
// import UserFlowFullPage from "pages/FASTag/UserFlowFullPage";
// import CategoriesFullPage from "pages/FASTag/CategoriesFullPage";

const Page = (props) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const onMenuBtnClick = () => {
        console.log(isSidebarOpen);
        setIsSidebarOpen(!isSidebarOpen);
    };

    // onClickOutside() {
    // 	if(this.state.isSidebarOpen)
    // 		this.setState({ isSidebarOpen: !this.state.isSidebarOpen })
    // }

    return (
        <>
            {/* ////////////////////////////////////////////////////////////////////////////////////////////////////
                LEFT SIDEBAR
               ////////////////////////////////////////////////////////////////////////////////////////////////// */}
            {/* Not the best of solutions. But can be improved */}
            {/* <Route path="*"  component={withRouter(Sidebar)} /> */}

            <Sidebar visible={isSidebarOpen} location={props.location} />

            {/* ////////////////////////////////////////////////////////////////////////////////////////////////////
                MAIN CONTENT
                ////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <ContentWrapper>
                <Header onMenuBtnClick={onMenuBtnClick} />

                <TransitionGroup>
                    <CSSTransition
                        timeout={{ enter: 800, exit: 800 }}
                        classNames="fade"
                    >
                        <Switch>
                            <Route exact path="/" component={Home} />

                            <Route exact path="/api">
                                <Redirect to="/fastag/api"></Redirect>
                            </Route>

                            <Route
                                exact
                                path="/fastag/product"
                                component={Error404}
                            />

                            <Route exact path="/product">
                                <Redirect to="/fastag/product"></Redirect>
                            </Route>
                            <Route exact path="/integration">
                                <Redirect to="/fastag/guide"></Redirect>
                            </Route>
                            <Route exact path="/push-notifications">
                                <Redirect to="/fastag/push-notifications"></Redirect>
                            </Route>
                            <Route
                                exact
                                path="/fastag/push-notifications"
                                component={Error404}
                            />
                            <Route
                                exact
                                path="/fastag/push-notifications-axis"
                                component={Error404}
                            />
                            <Route
                                exact
                                path="/fastag/guide"
                                component={Error404}
                            />

                            <Route
                                exact
                                path="/fastag/api"
                                component={Error404}
                            />
                            <Route
                                exact
                                path="/cou/api"
                                component={CouAPIReference}
                            />
                            <Route
                                exact
                                path="/cou/instacollect/api"
                                component={InstaCollectAPIReference}
                            />

                            <Route
                                exact
                                path="/encryption"
                                component={Error404}
                            />

                            <Route exact path="/fastag" component={Error404} />

                            <Route
                                exact
                                path="/fastag/error-codes"
                                component={Error404}
                            />

                            <Route
                                exact
                                path="/fastag/bc-guide"
                                component={Error404}
                            />
                            <Route
                                exact
                                path="/fastag/recharge-guide"
                                component={Error404}
                            />
                            <Route
                                exact
                                path="/fastag/java-sdk-guide"
                                component={Error404}
                            />
                            <Route
                                exact
                                path="/product/business-correspondent"
                                component={Error404}
                            />

                            <Route
                                exact
                                path="/product/corporate"
                                component={Error404}
                            />
                            <Route
                                exact
                                path="/product/integration"
                                component={Error404}
                            />
                            <Route
                                exact
                                path="/product/user-flow"
                                component={Error404}
                            />

                            <Route
                                exact
                                path="/product/categories"
                                component={Error404}
                            />
                            <Route
                                exact
                                path="/bbps/biller/guide"
                                component={BbpsBiller}
                            />
                            <Route exact path="/bbps/biller/docs">
                                <Redirect to="/bbps/biller/guide"></Redirect>
                            </Route>
                            <Route exact path="/bbps/jwt" component={JWT} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </ContentWrapper>
        </>
    );
};

export default withRouter(Page);
