import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Content from "./components/Page/Page";

class App extends React.PureComponent {
    render() {
        return (
            <Router basename={process.env.PUBLIC_URL}>
                <Content />
            </Router>
        );
    }
}

export default App;
