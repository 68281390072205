import React, { Component } from 'react';
import { RedocStandalone } from 'redoc';

import spec from '../../spec/cou.json';
import customTheme from '../../theme/theme';

class CouAPIReference extends Component {
    componentDidMount() {
        document.title = 'Axis API Reference';

        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div id="page-api-ref">
                <RedocStandalone
                    spec={spec}
                    options={{
                        nativeScrollbars: true,
                        theme: customTheme,
                    }}
                />
            </div>
        );
    }
}

export default CouAPIReference;
