import React from "react";

import { Row, Portion } from "reactised-fictoan";

class Error404 extends React.PureComponent {
    componentDidMount() {
        document.title = "404 — Setu";

        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Row
                    id="overview"
                    className="padded-content double-padding margin-top-small"
                >
                    <Portion className="whole no-padding-bottom">
                        <h1 className="text-red-80 horizontally-centre-this text-centre">
                            4-oh-4!
                        </h1>
                        <h1 className="weight-400 horizontally-centre-this text-centre">
                            —
                        </h1>
                    </Portion>

                    <Portion className="whole horizontally-centre-this">
                        <h6 className="weight-400 horizontally-centre-this text-centre">
                            <a
                                className="weight-400"
                                href="/"
                                rel="noreferrer noopener"
                            >
                                Go back to home page
                            </a>{" "}
                        </h6>
                    </Portion>
                </Row>
            </div>
        );
    }
}

export default Error404;
